import React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Slider from 'react-slick';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import './HomeCarousel.scss';

class HomeCarousel extends React.Component {

  drawSlides(slides) {
    const slideContent = slides.map((s, i) => {
      return (
        <div className="slide" key={i}>
          <Img fluid={s.image.localFile.childImageSharp.fluid} />
          <div className="slider-text">
            <h1
              dangerouslySetInnerHTML={{ __html: s.title }}
            >
            </h1>
          </div>
          <div className="get-in-touch-button has-text-centered">
            <Link
              className="button is-success"
              to="/contact">
              Get In Touch
            </Link>
          </div>
        </div>
      )
    });

    return slideContent;
  }

  render() {
    var settings = {
      autoplay: true,
      autoplaySpeed: 12000,
      arrows: false,
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div className="slider-wrapper">

        <Slider ref={slider => (this.slider = slider)} className="home-carousel" {...settings}>
          {this.drawSlides(this.props.slides)}
        </Slider>

        <MdKeyboardArrowLeft onClick={e => this.slider.slickPrev()} className="is-hidden-mobile prev-arrow" />
        <MdKeyboardArrowRight onClick={e => this.slider.slickNext()} className="is-hidden-mobile next-arrow" />
      </div>
    );
  }
}

export default HomeCarousel;
