import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HomeCarousel from '../components/HomeCarousel/HomeCarousel'
import HomeTiles from '../components/HomeTiles/HomeTiles'

export const PageTemplate = ({ title, content, slides, products, features }) => {
  return (
    <section>
      <HomeCarousel slides={slides}/>
      <div className="container">

      </div>
      <HomeTiles tiles={products} wrapperClass="products_services" columnSize="is-full-mobile is-half-tablet is-one-quarter-desktop"/>
      <HomeTiles tiles={features} wrapperClass="features" columnSize="is-full-mobile is-one-third-tablet is-one-fifth-desktop"/>
    </section>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data }) => {
  const { wordpressPage: page } = data

  return (
    <Layout>
      <SEO
        title={page.title}
        description={(page.acf.meta_description ? page.acf.meta_description : null)}
      />
    <PageTemplate
      title={page.title}
      content={page.content}
      slides={page.acf.slider}
      products={page.acf.products__services}
      features={page.acf.features}
    />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query HomePageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      acf {
        meta_description
        slider {
          title
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        products__services {
          title
          text
          link_to
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 450) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        features {
          title
          text
          link_to
          image {
            localFile {
              childImageSharp {
                fixed(width: 61, height: 61) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
