import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { reduce } from 'lodash';

import './HomeTiles.scss';

class HomeTiles extends React.Component {

  _drawTileImage(t) {
    if (t.link_to) {
      return (
        <Link to={t.link_to}>
          <Img fixed={t.image.localFile.childImageSharp.fixed} />
        </Link>
      )
    } else {
      return <Img fixed={t.image.localFile.childImageSharp.fixed} />
    }
  }

  _drawTileTitle(t) {
    if (t.link_to) {
      return (
        <Link to={t.link_to}>
          <h3
            className="is-size-4"
            dangerouslySetInnerHTML={{ __html: t.title }}
          />
        </Link>
      )
    } else {
      return (
        <h3
          className="is-size-4"
          dangerouslySetInnerHTML={{ __html: t.title }}
        />
      )
    }
  }

  render() {
    const { tiles, wrapperClass, columnSize } = this.props;
    let content;

    if (wrapperClass === 'products_services') {
      const largeTile = tiles[0];

      return (
        <div className={wrapperClass+'_wrapper home-tiles'}>
          <div className="container">
            <div className="columns is-vcentered is-multiline has-text-centered">
              <div className="column is-3 small-tiles-wrapper">
                {[tiles[1], tiles[2]].map((t, i) => {
                  return (
                      <Link to={t.link_to} className="product-link">
                        <Img fluid={t.image.localFile.childImageSharp.fluid} />
                        <h4 className="has-text-white" dangerouslySetInnerHTML={{ __html: t.title }}></h4>
                      </Link>
                  )
                })}
              </div>
              <div className="column is-6 large-tile has-text-centered">
                <Link to={largeTile.link_to} className="product-link">
                  <Img fluid={largeTile.image.localFile.childImageSharp.fluid} />
                  <h4 className="has-text-white" dangerouslySetInnerHTML={{ __html: largeTile.title }}></h4>
                </Link>
              </div>
              <div className="column is-3 small-tiles-wrapper">
                  {[tiles[3], tiles[4]].map((t, i) => {
                    return (
                        <Link to={t.link_to} className="product-link">
                          <Img fluid={t.image.localFile.childImageSharp.fluid} />
                          <h4 className="has-text-white" dangerouslySetInnerHTML={{ __html: t.title }}></h4>
                        </Link>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      )

    } else if (wrapperClass === 'features') {
      content = tiles.map((t, i) => {
        return (
          <div key={i} className={'column '+columnSize}>
            {this._drawTileImage(t)}
            <div className="text">
              {this._drawTileTitle(t)}
              <p>{t.text}</p>
            </div>
          </div>
        )
      });

      return (
        <div className={wrapperClass+'_wrapper home-tiles'}>
          <div className="container">
            <div className="columns is-mobile is-multiline has-text-centered">
              {content}
            </div>
          </div>
        </div>
      )

    }

  }
}

export default HomeTiles;
